@import '../theme-bootstrap';

body,
body.toolbar-drawer,
body.toolbar {
  padding-top: 0 !important;
}

body {
  -webkit-print-color-adjust: exact;
}

.region #togglemenus-collapsed,
.region #toolbar {
  display: none;
}

.invisible.focusable,
#footer,
.nav-menu .invisible {
  display: none;
}
/* My Account - Favourites */
.account-header-panel,
.account.breadcrumb.panel,
.my-account-sku .add-to-bag,
.product-tout .add-to-bag,
.account-favorites .favorite-actions a,
.account-favorites-mylists,
.navigation-right {
  display: none;
}

.favorite-products {
  margin-top: 0;
}

.favorite-products-grid .favorite {
  list-style: none;
}

.favorite-products-grid .favorite {
  height: auto;
}

#sticky-bar {
  position: relative;
}

.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .product-info {
  width: 50%;
}

.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .product-info .product-name {
  margin: 0px 3% 0 0;
  width: 40%;
}

.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .product-info .price {
  margin: 0px;
}

.favorite .favorite-product .add-to-bag,
.nav-menu .content,
.offer-promo-v1 .content,
.favorite-products .favorite-actions a {
  display: block;
}

.offer-promo-v1,
.cycle-slide {
  height: 90px;
  overflow: hidden;
}

.favorite-products .favorite-actions a {
  float: left;
}

.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .shades .shade-name,
.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .sizes .shade-name {
  width: 65%;
  word-break: break-word;
  float: left;
}

.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .shades .search-swatch-container,
.account-favorites-wishlist .favorite-products-grid .favorite .favorite-product .sizes .search-swatch-container {
  float: left;
  width: 20%;
}

.account-favorites .grey-gradient,
.account-favorites-wishlist .grey-gradient {
  background-size: contain;
}
